import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const AbsentMan = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Absent Man's Bargain" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>The Absent Man's Bargain</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Dec. 21st, 2024) </p>
        </div>
        <div className="articleBody">
          <p>
            I don’t know what my readership is like these days, but if you’re
            reading this: hello and I love you very much my little snookums, goo
            goo gaga my sweet little reader!!! LOL.
          </p>
          <p>
            I haven’t been able to publish here as often as I’d like. There are
            a couple reasons that explain my absence:{" "}
          </p>
          <p>
            The first reason is that I’ve been off of social media for long
            enough now (over 2 years!!!) that I’m not quite as tapped into the
            zeitgeist as I once was. That’s a blessing and a curse. My brain is
            a lot less crowded with useless shit, and I’m not wasting hours of
            my life on mindless short-form content (I used to blink and miss
            large chunks of my life, so{" "}
            <a
              href="https://shoya.co/shoyaright/vol3/is-cool-a-virtue"
              target="_blank"
              rel="noopener noreferrer"
            >
              uncool
            </a>
            ). On the other hand, I sometimes feel a bit disconnected from the
            People, and I’ve missed out on countless memes (I didn’t even hear
            about those billionaires dying in a submarine til months after it
            happened smh). It’s hard to call it all “useless” shit, cuz a lot of
            what I would see and read about on socials ended up being fodder for
            the things I’d write about here. I’ve bridged the gap a little with
            RSS feeds and email newsletters (damn near gave y’all 5000 words on
            Sam Bankman-Fried plotting the assassination of Trump, you better
            open your 4th eye!!!) but I definitely miss the magic of getting hot
            and cold takes in real time. I also used to post links to these
            articles on Twitter which is……honestly where all my traffic came
            from lol so it was starting to feel like screaming into a void. I’m
            thinking of making this into an email newsletter as a way to keep up
            with old friends, or maybe I’ll just stare into the abyss like
            Freaky Freddie Neetzsche said I don’t got time for spell check rn
            y’all know what I’m on!!!{" "}
          </p>
          <p>
            The second reason I’ve been away so long is kinda related to all
            that. What motivated me to put down my phone and reclaim my time
            (word to Maxine Waters lmao if that reference doesn't prove I been
            off socials idk what will) is that for the past 18 months I’ve been
            pursuing my adolescent dream of making a feature-length
            documentary!!! Y’all know I love me some cinema, I’ve written about
            it{" "}
            <a
              href="https://shoya.co/shoyaright/vol1/atlantique"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{" "}
            and{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/black-nonfiction-cinema"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{" "}
            and{" "}
            <a
              href="https://shoya.co/shoyaright/vol4/filmmaker-starting-5"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . I’m currently at a 74-minute rough cut, which feels absolutely
            surreal. It’s still extremely rough around the edges, and I have no
            clue how much longer it’ll take to get it across the finish line,
            but I feel so utterly grateful to have received support from some
            great organizations so far (
            <a
              href="https://truefalse.org/rough-cut-retreat/"
              target="_blank"
              rel="noopener noreferrer"
            >
              True/False & Catapult Film Fund
            </a>
            ,{" "}
            <a
              href="https://www.independencemedia.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              IPMF
            </a>
            ,{" "}
            <a
              href="https://caamedia.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CAAM
            </a>
            ,{" "}
            <a href="" target="_blank" rel="noopener noreferrer">
              Scribe Video Center
            </a>
            ,{" "}
            <a
              href="https://www.blackstarfest.org/sponsored-projects/the-nile-splits/"
              target="_blank"
              rel="noopener noreferrer"
            >
              BlackStar
            </a>
            ).{" "}
          </p>
          <p>
            My life has been deeply enriched by engaging with films from around
            the world. I grew up in a humble family without a lot of means, so
            travel wasn’t something that I got to experience very often. It was
            through cinema that I got to expand my worldview, and learn about
            this fascinating planet of ours and the precious lives unfolding on
            it. Cinema has given me so much, I figure it's time I give a little
            back.
          </p>
          <p>
            I recently heard an African virologist on a newscast, an older man
            probably in his 60’s or 70’s. He was there to talk about a recent
            Zika outbreak. The host introduced him as Dr. So-And-So, and noted
            that he had been studying viruses for over four decades. The doctor
            said in response, in an endearing West African accent, “Yes I’ve
            been studying viruses. But the viruses, they’ve been studying me
            too!” and gave a big belly laugh.{" "}
          </p>
          <p>
            And so that’s the bargain I’m making - logging off and letting my
            ideas gestate in hopes that when I return, I’ll have something
            worthwhile to contribute. The risk, of course, is that by the time I
            complete the work I’ll be out of touch w/ the cultural context into
            which it’ll be born. It’s a risk I’m willing to take, because
            between you and me, the satisfaction is in the making, not the film
            itself. Similar to the African virologist, I’ve been making this
            film, but the film has been making me too! Hahahaha.{" "}
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020 - &infin;</p>
        </div>
      </div>
    </div>
  )
}
export default AbsentMan
